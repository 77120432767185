<template>
    <div class="ant-spin-nested-loading">
        <div class="ant-spin-container">
            <div class="detail-content">
                <div class="detail-content-bottom">
                    <div class="thing-content-view">
                        <el-row :gutter="24" style="margin-top: 2%;">
                            <el-col :span="20">
                                <el-card class="box-card">
                                    <div class="main-content">
                                        <h1 class="title">{{ info.title }}</h1>
                                        <p class="source">来源：{{ info.source }} &nbsp;&nbsp; 发布时间：
                                            {{ formatCreatedAt(info.createdAt) }}</p>
                                        <div v-html="info.text"></div>
                                        <div style="display: flex;justify-content: center;">
                                            <el-button @click="like">点赞 ({{ likes }})</el-button>
                                            <el-button @click="collect">收藏 ({{ collections }})</el-button>
                                            <el-button @click="share">分享</el-button>
                                        </div>
                                        <!-- <el-tabs v-model="activeTab">
                                            <el-tab-pane label="评论">
                                                <div class="thing-comment">
                                                    <div class="title">发表新的评论</div>
                                                    <el-input v-model="newComment" placeholder="说点什么..."></el-input>
                                                    <el-button @click="sendComment">发送</el-button>

                                                    <div class="comments-list">
                                                        <div v-for="comment in comments" :key="comment.id"
                                                            class="comment-item">
                                                            <el-row>
                                                                <el-col :span="2">
                                                                    <img :src="comment.avatar" class="avator" />
                                                                </el-col>
                                                                <el-col :span="18">
                                                                    <div class="person">
                                                                        <span class="name">{{ comment.name }}</span>
                                                                        <span class="time">{{ comment.time }}</span>
                                                                    </div>
                                                                    <p class="comment-content">{{ comment.content }}</p>
                                                                </el-col>
                                                                <el-col :span="4" class="recommend">
                                                                    <span>推荐</span> <span>{{ comment.recommendNum
                                                                        }}</span>
                                                                </el-col>
                                                            </el-row>
                                                        </div>
                                                    </div>
                                                    <div v-if="comments.length === 0">没有更多了</div>
                                                </div>
                                            </el-tab-pane>
                                        </el-tabs> -->
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="4">
                                <el-card class="box-card">
                                    <div class="main-side">
                                        <div class="recommend" style="min-height: 300px;">
                                            <div class="title" style="">相关推荐</div>
                                            <div v-for="item in recommendedItems" :key="item.id" class="thing-item">
                                                <div v-if="recommendedItems.length <3">
                                                    <img class="img-view" :src="item.image" />
                                                    <h3 class="thing-name">{{ item.title }}</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </el-card>
                                <el-card class="box-card" style="margin-top: 10px;">
                                    <div class="main-side">
                                        <div class="recommend">
                                            <div class="title">广告</div>
                                            <div class="thing-item" v-for="ad in ads" :key="ad.id">
                                                <img class="img-view" :src="ad.image" />
                                            </div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { xinWenById, xinWenList } from "../api/xinwen";
import moment from 'moment';
export default {
    name: 'Detail',
    data() {
        return {
            title: '',
            source: '',
            date: '',
            description: '',
            imageUrl: '',
            conclusion: '',
            likes: 1,
            collections: 1,
            activeTab: '评论',
            newComment: '',
            info: '',
            recommendedItems: [
                { id: 1, image: 'https://news.gitapp.cn/api/upload/cover/1720232758564.jpeg', name: '学校举办2024年中外合' },
                // 更多推荐项目...
            ],
            ads: [
                { id: 1, image: 'https://news.gitapp.cn/api/upload/ad/1720235370354.jpeg' },
                // 更多广告...
            ],
            id: '',
        }
    },

    created() {
        this.id = this.$route.params.id
        xinWenById(this.$route.params.id).then(res => {
            if (res.data.code == 200) {
                this.info = res.data.data
                console.log("11111111", res.data.data)
            }
        })
        xinWenList().then(res => {
            if (res.data.code == 200) {
                this.recommendedItems = res.data.data.list
            }
        })

    },
    methods: {
        formatCreatedAt(createdAt) {
            // 使用 moment 解析时区并格式化时间
            return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
        },
    }
}
</script>
<style>
.ant-spin-nested-loading {
    position: relative;

    .ant-spin-container {
        position: relative;
        transition: opacity .3s;
    }

    .detail-content {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;

    }

    .thing-content-view {
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    .main-content {
        flex: 1;
        min-width: 300px;
        padding: 0 16px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 2px #0000000d;
    }

    .title {
        text-align: center;
        font-size: 18px;
        color: rgb(17, 17, 17);
        font-weight: bold;
        margin: 20px 0px;
    }

    .source {
        text-align: center;
        font-size: 14px;
        color: rgb(17, 17, 17);
        margin: 20px 0px;
    }

    .description {
        margin: 30px 0px;
        color: rgb(51, 51, 51);
        background-color: rgb(255, 255, 255);
    }

    .content-image {
        width: 691px;
        height: 460.32px;
    }

}

.main-side .recommend .title {
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding-left: 16px;
    color: #152844;
    border-bottom: 1px solid #eee;
}

.main-side .recommend .thing-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.main-side .recommend .thing-item .img-view {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 5/3;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.main-side .recommend .thing-item .thing-name {
    margin-top: 12px;
    color: #0f1111;
    font-size: 14px;
}
</style>